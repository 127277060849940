<template>
  <div>
    <Button @click="refreshTable" v-if="ajax" html-type="action" class="float-start" icon="material-symbols-light:refresh">Neu laden</Button>
    <DataTable :id="props.id" ref="table" :key="updateTable" class="dataTable" :options="options" :data="data" :columns="columns" />
  </div>
</template>

<script setup>
import DataTable from "datatables.net-vue3";
import { DATATABLE_LANGUAGE } from "~/constants/datatable";

const props = defineProps({
  id: { type: String },
  data: { type: Array },
  columns: { type: Array },
  drag: { type: Boolean },
  additionalData: { type: Array, default() { return []; } },
  ordering: { type: Boolean, default() { return true; } },
  filter: { type: Object },
  no_data: { type: String },
  ajax: { type: String },
  order: { type: Array },
  pageLength: { type: Number },
  optionsRowId: { type: String, default: "id" },
});

const emit = defineEmits([ "rowClick", "dragComplete", "tableDataChange" ]);

defineExpose({
  refreshTable, emitTableData,
});

const { toast } = useNuxtApp();
const updateTable = ref(0);
const client = useState("client");
const table = ref({});

const additionalData = ref(props.additionalData);
const filter = ref(props.filter);

const options = {
  rowId: props.optionsRowId,
  pageLength: props.pageLength || 100,
  processing: true,
  order: [],
  ordering: props.drag ? false : props.ordering,
  language: { ...DATATABLE_LANGUAGE },
  layout: {
    topStart: "",
    top: "search",
    topEnd: [ "pageLength", "paging" ],
    bottomEnd: [ "pageLength", "paging" ],
  },
  drawCallback() {
    const pages = this.api().page.info().pages;
    if (pages <= 1) $(`#${props.id} .dataTables_paginate`).addClass("d-none");
    else $(`#${props.id} .dataTables_paginate`).removeClass("d-none");
    const filteredData = this.api().rows({ filter: "applied" }).data().toArray();
    emitTableData(filteredData);
  },
};

if (props.order) { options.order = [ props.order ]; }

if (props.no_data) { options.language.emptyTable = props.no_data; }

if (props.ajax) {
  options.serverSide = true;
  options.ajax = {
    url: props.ajax,
    beforeSend(request) {
      request.setRequestHeader("Authorization", useToken().value);
    },
    error(xhr) {
      toast.showError("Fehler", xhr.responseJSON.message);
    },
  };
  options.ajax.data = getAjaxData();
}

onMounted(() => {
  $(document).on("click", `#${props.id} tbody tr`, function () {
    if (this.getAttribute("id")) emit("rowClick", this);
  });

  if (props.drag) {
    $(`#${props.id} tbody`).sortable({
      items: "tr",
      cursor: "move",
      opacity: 0.6,
      update() {
        emit("dragComplete", getSortData());
      },
    });
  }
});

function emitTableData(data) {
  emit("tableDataChange", data);
}

function getSortData() {
  const sortedIds = [];
  $(`#${props.id} tbody tr`).each((key, row) => {
    sortedIds.push(row.getAttribute("id"));
  });
  return sortedIds;
}

function refreshTable() {
  const dt = table.value.dt;
  if (dt) {
    dt.ajax.reload();
  }
}

function getAjaxData() {
  const data = [];
  if (props.ajax) {
    if (client.value && !props.additionalData.client_id) data.client_id = client.value.id;
    if (props.filter && props.filter.resource) data.filter_id = props.filter.resource.id;
    if (props.filter && props.filter.standard_options) {
      data.standard_options = [];
      props.filter.standard_options.forEach((_option) => {
        if (_option.value) data.standard_options.push(`${_option.data} = '${_option.value}'`);
      });
    }
    if (props.additionalData) {
      props.additionalData.forEach((_data) => {
        data[_data.key] = _data.value;
      });
    }
  }
  return data;
}

if (additionalData.value) {
  watch(additionalData.value, () => {
    options.ajax.data = getAjaxData();
    updateTable.value++;
  });
}

if (filter.value) {
  watch(filter.value, () => {
    options.ajax.data = getAjaxData();
    updateTable.value++;
  });
}
</script>
