export const DATATABLE_LANGUAGE = {
  search: "",
  searchPlaceholder: "Suchen",
  paginate: {
    first: "Erste",
    last: "Letzte",
    next: "Nächste",
    previous: "Zurück",
  },
  loadingRecords: "Lädt...",
  zeroRecords: "Keine Ergebnisse gefunden",
  emptyTable: "Keine Ergebnisse gefunden",
  info: "",
  infoEmpty: "Zeige 0 von 0 Einträgen",
  infoFiltered: "",
  lengthMenu: "_MENU_ Max. Einträge pro Seite",
  processing: "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'><path fill='none' stroke='currentColor' stroke-dasharray='16' stroke-dashoffset='16' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 3c4.97 0 9 4.03 9 9'><animate fill='freeze' attributeName='stroke-dashoffset' dur='0.2s' values='16;0'/><animateTransform attributeName='transform' dur='1.5s' repeatCount='indefinite' type='rotate' values='0 12 12;360 12 12'/></path></svg>",
};
